<template>
  <div class="widget-tabs">
    <div v-if="title" class="widget-tabs__title-wrapper mb_m">
      <div
        class="widget-tabs__title font font_title-xl font_bold font_uppercase"
        :class="{ 'font_center' : titleCenter }"
      >
        {{ title }}
      </div>
    </div>
    <Tabs class="widget-tabs__wrapper" :position="position" @change="onTabChange">
      <TabItem v-for="(tab, index) in items" :key="index" :title="tab.tabName">
        <Constructor
          v-show="index === activeTab"
          :items="tab.items"
          :title="title"
          :tab-name="tabName"
        />
      </TabItem>
    </Tabs>
  </div>
</template>

<script>
import Tabs from '~/components/elements/Tabs';
import TabItem from '~/components/elements/TabItem';
import Constructor from '~/components/catalog/constructor';

export default {
  name: 'WidgetTabs',
  components: {Constructor, TabItem, Tabs},
  props: {
    title: {
      type: String,
      default: '',
    },
    titleCenter: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: 'center',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
      tabName: this.items[0].tabName,
    }
  },
  methods: {
    onTabChange(value) {
      this.activeTab = value
      this.tabName = this.items[value].tabName
    },
  },
}
</script>

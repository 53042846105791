<template>
  <div class="tabs">
    <div
      v-swiper="sliderOptions"
      class="tabs__nav _swiper"
      :class="`tabs__nav_${position}`"
    >
      <div class="swiper-wrapper">
        <div v-for="(tab, index) in tabs" :key="index" class="swiper-slide">
          <div class="tabs__nav-item">
            <input
              :id="`tab-${index}`"
              type="radio"
              name="tab"
              class="tabs__nav-input"
              :checked="tab.isActive"
              @click="selectTab(index)"
            >
            <label
              :for="`tab-${index}`"
              class="tabs__nav-label font font_m font_bold font_uppercase"
              :class="{
                'tabs__nav-label--active': tab.isActive,
              }"
            >
              <SvgIcon
                v-if="tab.icon"
                :name="tab.icon"
                :class="tab.iconMode"
                width="24"
                height="24"
              />
              {{ tab.title }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import {directive} from 'vue-awesome-swiper';

export default {
  name: 'Tabs',
  directives: {
    swiper: directive,
  },
  props: {
    selectedInit: {
      type: Number,
      default: 0,
    },
    position: {
      type: String,
      default: 'left',
    },
  },
  data() {
    return {
      tabs: [],
      sliderOptions: {
        touchRatio: 1,
        observer: true,
        observeParents: true,
        spaceBetween: 12,
        slidesPerView: 'auto',
        centeredSlides: false,
        freeMode: true,
        watchSlidesVisibility: false,
        watchSlidesProgress: false,
        loop: false,
        loopPreventsSlide: false,
        resistance: true,
        resistanceRatio: 0.7,
        preloadImages: false,
        lazy: {
          loadOnTransitionStart: false,
          loadPrevNext: true,
        },
      },
    };
  },
  created() {
    this.tabs = this.$children;
  },
  mounted() {
    this.selectTab(this.selectedInit);
  },
  methods: {
    selectTab(index) {
      const activeTabIndex = this.tabs.findIndex(tab => tab.isActive)

      this.tabs.forEach((tab, tabIndex) => {
        tab.isActive = tabIndex === index;
      });

      if (this.tabs[index].$options.propsData.disabled) {
        this.tabs[activeTabIndex].isActive = true
        this.tabs[index].isActive = false
        this.$emit('errorChange', index);
      } else {
        this.$emit('change', index);
      }
    },
  },
};
</script>
